import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENVIRONMENT, PUBLIC_SENTRY_DSN } from '$env/static/public';
import { browser } from '$app/environment';

const initSentry = () => {
  if (!browser) {
    return;
  }

  if (PUBLIC_ENVIRONMENT === 'dev') {
    Sentry.init({
      tracesSampleRate: 0.1,
      profilesSampleRate: 0.1,
      dsn: PUBLIC_SENTRY_DSN,
      environment: PUBLIC_ENVIRONMENT
    });

    return;
  }
  Sentry.init({
    tracesSampleRate: PUBLIC_ENVIRONMENT === 'prod' ? 0.1 : 0,
    profilesSampleRate: PUBLIC_ENVIRONMENT === 'prod' ? 0.1 : 0,
    dsn: PUBLIC_SENTRY_DSN,
    environment: PUBLIC_ENVIRONMENT
  });
};

initSentry();

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();

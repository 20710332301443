import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [2];

export const dictionary = {
		"/auth/login": [~12,[5]],
		"/auth/logout": [13,[5]],
		"/(app)/dashboard/reports": [~6,[2,3]],
		"/(app)/dashboard/resources": [~7,[2,3]],
		"/(app)/dashboard/templates": [8,[2,3]],
		"/(app)/dashboard/templates/new/content": [9,[2,4]],
		"/(app)/dashboard/templates/new/details": [10,[2,4]],
		"/(app)/dashboard/templates/new/preview": [11,[2,4]],
		"/okta/callback": [~14],
		"/okta/login": [~15],
		"/sentry-example": [16]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';